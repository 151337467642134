
import Archivo from "@/Constantes/Archivo";

const STATUS_INCIADO = 0,
  STATUS_SELECCIONADO = 1;

export default {
  name: "input-archivo",
  props: {
    tiposArchivosAceptados: {
      type: String,
      default: Archivo.TIPOS_ARCHIVOS_ACEPTADOS,
    },
    alturaInput: {
      type: String,
      default: "85",
    },
  },
  data() {
    return {
      statusSubida: null,
      archivo: {
        name: "",
      },
      archivoExtencion: "",
    };
  },
  computed: {
    iniciado() {
      return this.statusSubida === STATUS_INCIADO;
    },
    seleccionado() {
      return this.statusSubida === STATUS_SELECCIONADO;
    },
    tamanioArchivo() {
      let tamanioBytes = this.archivo.size;
      if (tamanioBytes >= 1048576)
        return Number(tamanioBytes / 1048576).toFixed(2) + " MB";
      else if (tamanioBytes >= 1024)
        return Number(tamanioBytes / 1024).toFixed(2) + " KB";
      else if (tamanioBytes > 1) return tamanioBytes + " bytes";
      else if (tamanioBytes == 1) return tamanioBytes + " byte";
      else return "0 bytes";
    },
  },
  methods: {
    reset(resetPadre = false) {
      this.statusSubida = STATUS_INCIADO;
      this.archivo = {
        name: "",
      };
      this.archivoExtencion = "";

      if (resetPadre) this.$emit("guardar-archivo", this.archivo);
    },
    subirArchivo(archivos) {
      if (!archivos.length) return;

      this.archivo = archivos[0];

      // Se valida tamaño del archivo
      if (this.archivo.size > 20971520)
        return this.mostrarAlerta(
          "El archivo excede el tamaño máximo permitido (20MB)",
          "error"
        );

      // Se valida extencion del archivo
      if (!this.validarExtencion())
        return this.mostrarAlerta(
          `No se permite el tipo de archivo seleccionado (.${this.archivoExtencion})`,
          "error"
        );

      this.statusSubida = STATUS_SELECCIONADO;
      this.$emit("guardar-archivo", archivos[0]);
    },
    validarExtencion() {
      let extencion = this.archivo.name.split(/\.(?=[^.]+$)/)[1];

      if (extencion != undefined) {
        this.archivoExtencion = extencion;
        return this.tiposArchivosAceptados.includes(extencion.toLowerCase());
      }

      this.archivoExtencion = "Desconocida";
      return false;
    },
  },
  mounted() {
    this.reset();
  },
};
