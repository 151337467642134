import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "seguimientos", "listarGestor"),
  obtenerConcentradoOpciones: (filtros) =>
    RepoUtils.ejecutarPeticionGetV2(
      filtros,
      "seguimientos",
      "",
      "obtener_concentrado_opciones"
    ),
  obtenerDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "seguimientos", "obtenerDetalle"),

  // POST
  agregar: (data) => RepoUtils.ejecutarPeticionPostV2(data, "seguimientos"),
  actualizarStatus: (data, seguimientoId) =>
    RepoUtils.ejecutarPeticionPostV2(
      data,
      "seguimientos",
      seguimientoId,
      "actualizar_status"
    ),
  editar: (data, seguimientoId) =>
    RepoUtils.ejecutarPeticionPostV2(
      data,
      "seguimientos",
      seguimientoId,
      "editar"
    ),
};
