
import InputArchivo from "../inputs/InputArchivo.vue";
import constantesArchivo from "@/Constantes/Archivo.js";
export default {
  name: "modal-subir-archivo-global",
  components: {
    InputArchivo,
  },
  props: {
    model: { type: Boolean, required: true },
    tiposArchivosAceptados: {
      type: String,
      default: constantesArchivo.TIPOS_ARCHIVOS_ACEPTADOS,
    },
  },
  data() {
    return {
      mostrar: false,
      comentario: "",
      archivo: {
        name: "",
      },
    };
  },
  watch: {
    model(val) {
      this.mostrar = val;
    },
  },
  methods: {
    onClose() {
      this.comentario = "";
      this.archivo = {
        name: "",
      };
      this.$emit("cerrar-modal");
    },
    subirArchivo() {
      this.$emit("subir-archivo", this.archivo, this.comentario);
    },
    guardarArchivo(archivo) {
      this.archivo = archivo;
      if (this.archivo.name != "") this.$refs.inputComentario.focus();
    },
  },
};
