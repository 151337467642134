
import CardArchivoEmail from "../cards/CardArchivoEmailGlobal.vue";
export default {
  name: "modal-enviar-email-global",
  components: {
    CardArchivoEmail,
  },
  props: {
    model: { type: Boolean, required: true },
    tituloPrimario: { type: String, required: true },
    archivos: { type: Array, required: true },
    emailDestinatario: { type: String, required: true },
    tituloSecundario: { type: String, default: "Elegir archivos" },
    labelAceptar: { type: String, default: "Enviar" },
    mensajeRequerido: { type: Boolean, default: true },
  },
  data() {
    return {
      mostrar: false,
      emailObj: {
        cc: "",
        asunto: "",
        mensaje: "",
        archivos: [],
      },
      opcionesToolbar: [["bold", "italic", "underline", "strike"]],
      definitions: {
        bold: { icon: "icon-ol-texto-negritas" },
        italic: { icon: "icon-ol-texto-italica" },
        underline: { icon: "icon-ol-texto-subrayado" },
        strike: { icon: "icon-ol-texto-tachado" },
      },
    };
  },
  watch: {
    model(val) {
      this.mostrar = val;
    },
  },
  methods: {
    onClose() {
      this.emailObj = {
        cc: "",
        asunto: "",
        mensaje: "",
        archivos: [],
      };
      this.$emit("cerrar-modal");
    },
    enviarEmail() {
      let correosValidos = true;
      if (!_.isEmpty(this.emailObj.cc)) {
        correosValidos = this.validarCorreosCC();
        if (!correosValidos) return false;
      }
      if (this.mensajeRequerido && this.emailObj.mensaje == "")
        return this.mostrarAlerta("Ingresa el mensaje del email", "error");
      this.emailObj.archivos = this.archivos;
      this.$emit("enviar-email", _.cloneDeep(this.emailObj));
    },
    validarCorreosCC() {
      let correosCc = this.emailObj.cc.replaceAll(" ", "");
      if (correosCc.substring(correosCc.length - 1) == ",") {
        this.mostrarAlerta(
          "Omite el uso de coma (,) al final de los correos CC",
          "error"
        );
        return false;
      }
      let correosValidos = true;
      correosCc = correosCc.split(",");
      correosCc.forEach((c) => {
        if (this.validarCorreo(c) != true) {
          this.mostrarAlerta(`El correo "${c}" es un correo inválido`, "error");
          correosValidos = false;
        }
      });
      return correosValidos;
    },
    regresarUltimoElemento() {
      document.querySelector('[tabindex="4"]').focus();
    },
    regresarBtnGuardar() {
      document.querySelector('[tabindex="100"]').focus();
    },
    irPrimerElemento() {
      document.querySelector('[tabindex="3"]').focus();
    },
  },
};
