
import OportunidadService from "../../Services/OportunidadService";
import EmailService from "../../Services/EmailService";
import EmbudoService from "../../Services/EmbudoService";
import SeguimientoService from "../../Services/SeguimientoService";
import HORAS from "@/Constantes/Fecha";
import ModalSubirArchivoGlobal from "@/components/modales/ModalSubirArchivoGlobal.vue";
import ModalEnviarEmailGlobal from "@/components/modales/ModalEnviarEmailGlobal.vue";
import CardEmailDetalle from "./../emails/componentes/CardEmailDetalle.vue";
import ModalCerrarOportunidad from "./componentes/ModalCerrarOportunidad.vue";

export default {
  name: "oportunidad-detalle",
  components: {
    ModalSubirArchivoGlobal,
    ModalEnviarEmailGlobal,
    ModalCerrarOportunidad,
    CardEmailDetalle,
  },
  data() {
    return {
      breadcrumbs: [
        { etiqueta: "Oportunidades", to: { name: "oportunidadesGestor" } },
        { etiqueta: "Detalle" },
      ],
      opcionesToolbar: [],
      oportunidadId: this.$route.params.id,
      horaOpciones: HORAS.ARRAY_HORAS_POR_CUARTO,
      oportunidadObj: {},
      archivos: [],
      cotizaciones: [],
      columnasArchivos: [
        {
          label: "Nombre",
          field: "nombre_original",
          align: "left",
          headerStyle: "width: 20%",
          name: "nombre",
        },
        {
          label: "Comentario",
          field: "comentario",
          align: "left",
          headerStyle: "width: 25%",
        },
        {
          label: "Tamaño",
          field: "tamanio_humano",
          align: "left",
          headerStyle: "width: 10%",
        },
        {
          label: "Registro",
          field: "registro_fecha",
          align: "left",
          headerStyle: "width: 10%",
          format: (val) => this.formatearFecha(val, 2),
        },
        {
          label: "Autor",
          field: "registro_autor",
          align: "left",
          headerStyle: "width: 10%",
        },
        {
          name: "opc",
          label: "",
          align: "left",
          headerStyle: "width: 5%",
        },
      ],
      seguimientos: [],
      columnasSeguimientos: [
        {
          name: "folioSeguimiento",
          label: "#Seguimiento",
          field: "folio_seguimiento",
          align: "center",
          headerStyle: "width: 8%",
        },
        {
          label: "Tipo seguimiento",
          field: "tipo_seguimiento_nombre",
          align: "left",
          headerStyle: "width: 12%",
        },
        {
          label: "Descripción",
          field: "comentario",
          align: "left",
          headerStyle: "width: 43%",
        },
        {
          name: "status",
          label: "Status",
          align: "left",
          headerStyle: "width: 10%",
        },
        {
          name: "recordatorioFecha",
          label: "Fecha",
          align: "left",
          headerStyle: "width: 12%",
        },
        {
          label: "Agente asignado",
          field: "registro_autor",
          align: "left",
          headerStyle: "width: 15%",
        },
      ],
      emailEnviados: [],
      columnasEmailEnviados: [
        {
          label: "#Email",
          field: "folio_email",
          align: "center",
          headerStyle: "width: 10%",
        },
        {
          label: "Asunto",
          field: "asunto",
          align: "left",
          headerStyle: "width: 50%",
        },
        {
          label: "Status",
          align: "left",
          name: "status",
          headerStyle: "width: 15%",
        },
        {
          label: "Registro",
          field: "registro_fecha",
          align: "left",
          headerStyle: "width: 15%",
          format: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "opc",
          label: "",
          align: "left",
          headerStyle: "width: 5%",
        },
      ],
      editarOportunidadObj: {},
      modalSubirArchivo: false,
      tipoArchivo: "",
      modalEnviarEmail: false,
      archivosCorreo: [],
      modalEditarDescripcion: false,
      menuStatusEmbudoOpciones: [],
      emailObj: {},
      modalVerEmail: false,
      modalAgregarSeguimiento: false,
      tipoSeguimientoOpciones: [],
      agregarSeguimientoObj: {
        tipoSeguimientoId: "",
        fecha: "",
        hora: "",
        comentario: "",
      },
      modalCerrarOportunidad: false,
      embudoOportunidadCerradaId: "",
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerStatusEmbudo(false);
      await this.obtenerDetalle(false);
      this.loader(false);
    },
    async obtenerStatusEmbudo(loader = true) {
      if (loader) this.loader(true);
      await EmbudoService.listadoBasico({ ordenar: "orden_asc" })
        .then((resp) => {
          // Se configuran status de embudo
          this.configurarStatusEmbudo(resp);
          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        oportunidadId: this.oportunidadId,
      };
      await OportunidadService.obtenerDetalle(datos)
        .then((resp) => {
          this.oportunidadObj = resp.info;
          this.archivos = resp.archivos;
          this.cotizaciones = resp.cotizaciones;
          this.emailEnviados = resp.emailEnviados;
          this.seguimientos = resp.seguimientos;

          this.llenarOpcionesToolbar();
          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async obtenerDetalleEmail(email) {
      this.loader(true);
      let datos = {
        emailId: email.mensaje_email_id,
      };
      await EmailService.obtenerDetalle(datos)
        .then((resp) => {
          this.emailObj = email;
          this.emailObj.archivos = resp.archivos;
          this.loader(false);
          this.modalVerEmail = true;
        })
        .catch((error) => {
          this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async obtenerConcentradoOpcionesSeguimientos(loader = true) {
      if (loader) this.loader(true);
      await SeguimientoService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.tipoSeguimientoOpciones = resp.tiposSeguimientos;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async actualizarStatusEmbudo(statusNuevoObj) {
      this.loader(true);
      let datos = {
        oportunidadId: this.oportunidadId,
        embudoId: statusNuevoObj.embudo_id,
      };
      await OportunidadService.actualizarEmbudo(datos)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async actualizarStatusOportunidad(statusNuevo, embudoId) {
      this.loader(true);
      let datos = {
        oportunidadId: this.oportunidadId,
        status: statusNuevo,
        embudoId: embudoId,
      };
      await OportunidadService.actualizarStatus(datos)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    subirArchivo(archivo, comentario) {
      this.loader(true);
      let datos = {
        oportunidadId: this.oportunidadId,
        leadId: this.oportunidadObj.lead_id,
        archivo: archivo,
        tipo: this.tipoArchivo,
        comentario: comentario,
      };
      OportunidadService.subirArchivo(datos)
        .then(async (resp) => {
          this.cerrarModalSubirArchivo();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async enviarEmail(email) {
      // Se obtienen archivos seleccionados
      let archivosSeleccionados = email.archivos.filter(
        (a) => a.seleccionado == true
      );

      // Se valida que existan archivos seleccionados
      if (_.isEmpty(archivosSeleccionados))
        return this.mostrarAlerta(
          "No se ha seleccionado ningún archivo",
          "error"
        );

      this.loader(true);

      let datos = {
        oportunidadId: this.oportunidadId,
        leadId: this.oportunidadObj.lead_id,
        destinatarioNombre: this.oportunidadObj.contacto_nombre_completo,
        destinatarioEmail: this.oportunidadObj.contacto_email,
        asunto: email.asunto,
        cc: email.cc,
        mensaje: email.mensaje,
        archivosAdjuntos: JSON.stringify(archivosSeleccionados),
      };

      await OportunidadService.enviarEmail(datos)
        .then(async (resp) => {
          this.cerrarModalEnviarEmail();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    editarDescripcion() {
      this.loader(true);
      let datos = {
        oportunidadId: this.oportunidadId,
        descripcion: this.editarOportunidadObj.descripcion,
      };
      OportunidadService.editarDescripcion(datos)
        .then(async (resp) => {
          this.cerrarModalEditarDescripcion();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async agregarSeguimiento(loader = true) {
      // Se arma fecha de recordatorio
      let recordatorioFecha = this.dayjs(
        `${this.agregarSeguimientoObj.fecha} ${this.agregarSeguimientoObj.hora}`,
        "DD/MM/YYYY h:mm a"
      ).format("YYYY-MM-DD HH:mm:ss");

      // Se valida fecha de recordatorio
      let fechaActual = this.dayjs().format("YYYY-MM-DD HH:mm");
      if (this.dayjs(recordatorioFecha).isBefore(fechaActual)) {
        this.mostrarAlerta(
          `La fecha del recordatorio no puede ser menor a ${fechaActual}`,
          "error"
        );
        return "";
      }

      if (loader) this.loader(true);
      let datos = {
        oportunidadId: this.oportunidadId,
        leadId: this.oportunidadObj.lead_id,
        ...this.agregarSeguimientoObj,
        recordatorioFecha,
      };
      await SeguimientoService.agregar(datos)
        .then(async (resp) => {
          this.cerrarModalAgregarSeguimiento();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    llenarOpcionesToolbar() {
      this.opcionesToolbar = [
        {
          texto: "Enviar cotizacion",
          icono: "correo",
          accion: "abrirModalEnviarEmail",
          disabled:
            this.oportunidadObj.status ==
              this.constantesStatusCadena.oportunidadDescartada ||
            !this.oportunidadObj.contacto_email ||
            !this.permisos.existePermiso(
              this.permisos.OPORTUNIDADES_COTIZACION_ENVIAR
            ),
        },
        {
          texto: "Agregar seguimiento",
          icono: "seguimiento",
          accion: "abrirModalAgregarSeguimiento",
          disabled:
            this.oportunidadObj.status ==
              this.constantesStatusCadena.oportunidadDescartada ||
            !this.permisos.existePermiso(
              this.permisos.OPORTUNIDADES_SEGUIMIENTO_CREAR
            ),
        },
      ];
    },
    async manejarActualizacionStatus(statusNuevo) {
      if (this.oportunidadObj.embudo_color == statusNuevo) return;

      let statusObj = this.menuStatusEmbudoOpciones.find(
        (s) => s.color == statusNuevo
      );

      // Se valida tipo de embudo descartado
      if (statusNuevo == this.constantesStatusCadena.embudoRojo500) {
        let statusOportunidad =
          this.constantesStatusCadena.oportunidadDescartada;
        return this.actualizarStatusOportunidad(
          statusOportunidad,
          statusObj.embudo_id
        );
      }

      // Se valida tipo de embudo cerrado
      if (statusNuevo == this.constantesStatusCadena.embudoVerde500) {
        this.embudoOportunidadCerradaId = statusObj.embudo_id;
        this.modalCerrarOportunidad = true;
        return;
      }

      // Se actualiza solo embudo
      this.actualizarStatusEmbudo(statusObj);
    },
    async descargarArchivo(oportunidadArchivo) {
      this.loader(true);
      let nombreArchivo = oportunidadArchivo.nombre_sistema;
      let extension = oportunidadArchivo.extension;
      let tipo = oportunidadArchivo.tipo;
      let registroFecha = oportunidadArchivo.registro_fecha;
      let data = {
        oportunidadArchivoId: oportunidadArchivo.oportunidad_archivo_id,
        nombreArchivo,
        extension,
        tipo,
        registroFecha,
      };
      await OportunidadService.descargarArchivo(data)
        .then(async (resp) => {
          let linkDatos = `data:application/${extension};base64,${resp.datos.base64}`;
          let linkDescarga = document.createElement("a");
          linkDescarga.href = linkDatos;
          linkDescarga.download = nombreArchivo;
          linkDescarga.click();
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    abrirModalSubirArchivo(tipoArchivo) {
      this.tipoArchivo = tipoArchivo;
      this.modalSubirArchivo = true;
    },
    cerrarModalSubirArchivo() {
      this.tipoArchivo = "";
      this.modalSubirArchivo = false;
    },
    enviarCotizacion(val) {
      let archivo = _.cloneDeep(val);
      archivo.seleccionado = true;
      archivo.desabilitado = true;
      this.archivosCorreo = [archivo];
      this.modalEnviarEmail = true;
    },
    abrirModalEnviarEmail() {
      this.archivosCorreo = _.cloneDeep(this.cotizaciones);
      this.modalEnviarEmail = true;
    },
    cerrarModalEnviarEmail() {
      this.archivosCorreo = [];
      this.modalEnviarEmail = false;
    },
    abrirModalEditarDescripcion() {
      this.editarOportunidadObj = _.cloneDeep(this.oportunidadObj);
      this.modalEditarDescripcion = true;
    },
    cerrarModalEditarDescripcion() {
      this.editarOportunidadObj = {};
      this.modalEditarDescripcion = false;
    },
    configurarStatusEmbudo(embudos) {
      embudos.map((em) => {
        em.status = em.color;
        em.statusNombre = em.titulo;
      });
      this.menuStatusEmbudoOpciones = embudos;
    },
    cerrarModalVerEmail() {
      this.emailObj = {};
      this.modalVerEmail = false;
    },
    async abrirModalAgregarSeguimiento() {
      if (_.isEmpty(this.tipoSeguimientoOpciones))
        await this.obtenerConcentradoOpcionesSeguimientos();
      this.modalAgregarSeguimiento = true;
    },
    cerrarModalAgregarSeguimiento() {
      this.agregarSeguimientoObj = {
        tipoSeguimientoId: "",
        fecha: "",
        hora: "",
        comentario: "",
      };
      this.modalAgregarSeguimiento = false;
    },
    marcarSeguimientoVencido(seguimiento) {
      if (
        seguimiento.status != this.constantesStatusCadena.seguimientoPendiente
      )
        return false;
      return this.dayjs(seguimiento.recordatorio_fecha).isBefore(
        this.dayjs().format("DD/MMMM/YYYY HH:mm:ss")
      );
    },
    cerrarModalCerrarOportunidad() {
      this.embudoOportunidadCerradaId = "";
      this.modalCerrarOportunidad = false;
    },
    async manejarExitoCerrarOportunidad(mensaje) {
      this.cerrarModalCerrarOportunidad();
      await this.obtenerDetalle(true);
      this.mostrarAlerta(mensaje, "exito");
    },
  },
};
