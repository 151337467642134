
export default {
  name: "card-archivo-email-global",
  props: {
    archivo: { type: Object, required: true },
    campoNombre: { type: String, default: "nombre_original" },
    campoComentario: { type: String, default: "comentario" },
    mostrarIconoDescarga: { type: Boolean, default: false },
  },
  methods: {
    descargarArchivo(archivo) {
      this.$emit("descargar-archivo", archivo);
    },
  },
};
